export default function setToCurrency() {

    function setCurrency(value: number) {
        if( typeof value === 'undefined' || !value) return '-';

        const priceValue =  value.toLocaleString("nl-nl", {
            style: "currency",
            currency: "EUR",
        })

        return priceValue
    }

    return {
        setCurrency,
    }
}